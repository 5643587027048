import React from "react";
import Image from "gatsby-image";
import { useState } from "react";
import styled, { keyframes } from "styled-components";

const pic = keyframes`
    0% { opacity: 0;transform: translate3d(-200%, -50%, 0)}
    50% { opacity: 0; }
    51% { opacity: 1; }
    100% { opacity: 1; transform: translate3d(-50%, -50%, 0)}
`;
const overlay = keyframes`
    0% {
        transform: scaleX(0);
        transform-origin: 0% 50%;
    }
    50% {
        transform: scaleX(1);
        transform-origin: 0% 50%;
    }
    51% {
        transform: scaleX(1);
        transform-origin: 100% 50%;
    }
    100% {
        transform: scaleX(0);
        transform-origin: 100% 50%;
    }
`;
const StyledImage = styled(Image)`
  height: 100%;
  width: auto;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.3);
  animation: ${props => (props.isActive ? pic : null)} 2s
    cubic-bezier(0.62, 0.37, 0.08, 0.95);
  animation-fill-mode: forwards;
`;
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #efefef;
  position: absolute;
  top: 0;
  z-index: 2;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  animation: ${props => (props.isActive ? overlay : null)} 2s
    cubic-bezier(0.62, 0.37, 0.08, 0.95);
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  right: 0;
  flex: 1 0 auto;
  padding:0;
  overflow: hidden;
`;
const FillImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 2;
`;

const FancyImg = ({ src, alt }) => {
  const [active, setActive] = useState(false);

  return (
    <ImageWrapper>
      <FillImage>
        <StyledImage
          isActive={active}
          onStartLoad={() => setActive(true)}
          fluid={src}
          draggable={false}
          alt={alt}
        />
      </FillImage>
      <Overlay isActive={active} />
    </ImageWrapper>
  );
};
export default FancyImg;

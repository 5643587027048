import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/Hero";
import ContentField from "../components/contentField";
import CardContentField from "../components/cardContentField";
import ReverseContentField from "../components/reverseContentField";

const IndexPage = () => {
  const {
    allStrapiLandingpagecontents: {
      edges: {
        0: { node: data },
      },
    },
    allStrapiLawyers: { edges: Lawyers },
  } = useStaticQuery(
    graphql`
      query {
        allStrapiLawyers {
          edges {
            node {
              Email
              Phone
              Name
              ProfilePicture {
                childImageSharp {
                  fluid(quality: 99) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        allStrapiLandingpagecontents {
          edges {
            node {
              Email
              Phone
              Punchline
              HeroImage {
                childImageSharp {
                  fluid(quality: 99) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              Contentblock {
                Content
                ContentImage {
                  childImageSharp {
                    fluid(quality: 99) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                Title
              }
            }
          }
        }
      }
    `
  );
  const { Contentblock, ...HeroContent } = data;
  return (
    <Layout
      title="Hjem"
      keywords={[
        "VI§VE Advokater",
        "Advokatfirma",
        "Oslo",
        "Gunhild Vehusheia",
      ]}
    >
      <Hero data={HeroContent} />
      {Contentblock.length > 0 && <ContentField data={Contentblock[0]} />}
      {Lawyers.length && <CardContentField data={Lawyers} />}
      {Contentblock.length > 1 && (
        <ReverseContentField data={Contentblock[1]} />
      )}
      {Contentblock.length > 2 &&
        Contentblock.map((c, i) =>
          i < 2 ? null : i % 2 === 0 ? (
            <ContentField data={c} />
          ) : (
            <ReverseContentField data={c} />
          )
        )}
    </Layout>
  );
};

export default IndexPage;

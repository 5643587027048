import React from "react";
import styled from "styled-components";
import FancyImg from "./fancyImg";
import { Column, Title, Content } from "../globals/style";

const Wrapper = styled.div`
  background: ${(props) => (props.inverse ? "#1e1d22" : "#efefef")};
  color: ${(props) => (props.inverse ? "#efefef" : "#1e1d22")};
  min-height: calc(100vh - 80px);
  width: 100%;
`;
const InsideWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
  flex-wrap: wrap;
  margin: 0 auto;
`;
const ColumnInline = styled(Column)`
  align-items: center;
  padding: 2em;
  @media screen and (max-width: 991px) {
    padding: 0;
    flex-basis: 100%;
  }
`;
const ContentHolder = styled.div`
  width: 80%;
  @media screen and (max-width: 991px) {
    width: 100%;
    flex-basis: 100%;
    padding: 2em 1.5em;
  }
`;

const ReverseContentField = ({ data }) => (
  <Wrapper inverse>
    <InsideWrapper>
      <ColumnInline>
        <ContentHolder>
          <Title reverse>{data.Title}</Title>
          <Content reverse>{data.Content}</Content>
        </ContentHolder>
      </ColumnInline>
      <Column style={{ padding: 0 }}>
        <FancyImg
          alt={data.Title}
          src={data.ContentImage.childImageSharp.fluid}
        />
      </Column>
    </InsideWrapper>
  </Wrapper>
);
export default ReverseContentField;

import React from "react";
import styled from "styled-components";
import { Column } from "../globals/style";
import Logo from "../images/LOGO_LARGE_light.svg";
import FancyImg from "./fancyImg";

const FullScreenPart = styled.div`
  background: #1e1d22;
  min-height: calc(100vh - 80px);
  width: 100%;
`;
const LimitedWindow = styled.div`
  height: 100%;
  min-height: calc(100vh - 80px);

  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

const FullLogo = styled.object`
  width: 90%;
  width: 500px;
  max-width: 500px;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.75));
  margin-top: -4em;
  @media screen and (max-width: 991px) {
    margin-top: -26vh;
    z-index: 4;
    position: absolute;
    width: 90%;
  }
`;
const PunchLine = styled.h1`
  margin: 1em 2em;
  color: #efefef;
  font-family: Darker Grotesque;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: 991px) {
    margin: 0;
  }
`;
const OtherColumn = styled(Column)`
  @media screen and (max-width: 991px) {
    min-height: 50vh;
  }
`;
const StyledColumn = styled(Column)`
  min-height: calc(100vh - 80px);
  width: 50vw;
  right: 0;
  padding: 0;
  @media screen and (max-width: 991px) {
    width: 100%;
    min-height: calc(80vh - 40px);
  }
`;
const StickyElements = styled.div`
  position: -webkit-sticky; /* Safari */
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  top: 80px;
  z-index: 10;
  @media screen and (max-width: 991px) {
    max-width: 100vw;
    overflow: hidden;
  }
`;
const Sticky = styled.a`
  flex: 1;
  font-size: 1.2em;
  padding: 0.4em;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background-color: ${(props) => (props.inverse ? "#efefef" : "#1e1d22")};
  color: ${(props) => (!props.inverse ? "#efefef" : "#1e1d22")};
  @media screen and (max-width: 991px) {
    font-size: 1em;
    text-overflow: ellipsis;
  }
`;
const Hero = ({ data }) => {
  return (
    <FullScreenPart>
      <LimitedWindow>
        <OtherColumn>
          <FullLogo
            type="image/svg+xml"
            aria-label={"Vive Advokater stor logo"}
            alt={"Vive Advokater stor logo"}
            data={Logo}
          />
          <PunchLine>{data.Punchline}</PunchLine>
          <StickyElements>
            <Sticky href={`tel:${data.Phone}`}>{data.Phone}</Sticky>
            <Sticky inverse href={`mailto:${data.Email}`}>
              {data.Email}
            </Sticky>
          </StickyElements>
        </OtherColumn>
        <StyledColumn>
          <FancyImg
            alt={"Vive Advokater"}
            src={data.HeroImage.childImageSharp.fluid}
          />
        </StyledColumn>
      </LimitedWindow>
    </FullScreenPart>
  );
};

export default Hero;
